import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const Success = () => {
    // const [count, setcount] = useState(15)
    const location = useLocation()
    const navigate = useNavigate()
    let count = 15
    const myInterval = setInterval(myTimer, 1000);
    function myTimer() {
        const element = document.getElementById("demo");
        element.innerHTML = "Back to home " + count
        count = count - 1
        if (count==0) {
            clearInterval(myInterval)
            navigate("/")
        }
    }

    return (
        <>
            <div class="container">
                {location.pathname === "/success" ?
                    <div style={{ alignItems: "center" }} class="row justify-content-center align-center ">
                        <div class="col-md-5">
                            <div class="message-box _success">
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                                <h2> Your payment was successful </h2>
                                <p> Thank you for your payment. we will <br />
                                    be in contact with more details shortly </p>
                            </div>
                            <button id={"demo"} onClick={() => navigate("/")} style={{ width: "525px", }}>Back to home</button>
                        </div>
                    </div>
                    :
                    <div class="row justify-content-center">
                        <div class="col-md-5">
                            <div class="message-box _success _failed">
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                                <h2> Your payment failed </h2>
                                <p>  Try again later </p>
                            </div>
                            <button id={"demo"} onClick={() => navigate("/")} style={{ width: "525px", }}>Back to home</button>
                        </div>
                    </div>}

            </div>
        </>
    )
}
