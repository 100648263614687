import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

const CheckoutForm = ({ price, currency }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (price > 0) {
      axios.post('http://localhost:3001/create-payment-intent', { amount: price, currency })
        .then(response => setClientSecret(response.data.clientSecret))
        .catch(error => console.error(error));
    }
  }, [price, currency]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      console.error(error);
    } else {
      console.log('Payment successful:', paymentIntent);
    }
  };

  return (
    <form onSubmit={handleSubmit}  >
      <CardElement  className="CardElement"  />
      <button type="submit" disabled={!stripe || !clientSecret}>
        Pay {currency.toUpperCase()} {price / 100}
      </button>
    </form>
  );
};

export default CheckoutForm;
