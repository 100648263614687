import React from 'react';

const ProductCard = ({ product, setPriceId, currency, priceId }) => {
  return (
    <div style={{ position: "relative", }}>
      {product.prices[0]?.id == priceId ? <span onClick={() => { setPriceId(product.prices[0]?.id) }} style={{ position: "absolute", width: "20px", paddingRight: "10px", zIndex: "1", cursor: "pointer" }}>  <img width="25px" src={"https://dev-app.readabilitytutor.com/web/shopping-cart/v4/checked.png"}></img></span>
        : <span onClick={() => { setPriceId(product.prices[0]?.id) }} style={{ position: "absolute", width: "20px", paddingRight: "10px", zIndex: "1", cursor: "pointer" }}>  <img width="25px" src={"https://dev-app.readabilitytutor.com/web/shopping-cart/v4/no-check.png"}></img></span>}
      {product.name === "Annual" &&
        <div
          onClick={() => { setPriceId(product.prices[0]?.id) }}
          style={{
            float: "right",
            padding: "7px 14px 11px 17px",
            color: product.prices[0]?.id == priceId ? "#fff" : 'black',
            fontWeight: "bold",
            position: "absolute",
            top: "-10px",
            right: "0px",
            cursor: "pointer",
            backgroundRepeat: 'no-repeat',
            backgroundImage: product.prices[0]?.id == priceId ? `url("https://dev-app.readabilitytutor.com/web/shopping-cart/v4/Sale%20banner_Label-Transparent-Selected.png")` : `url("https://dev-app.readabilitytutor.com/web/shopping-cart/v4/Sale%20banner_Label-Transparent.png")`
          }}>
          Save 42%
        </div>}
      <div style={product.prices[0]?.id == priceId ? { backgroundColor: "#DDF7F5", border: "3px solid #00c3b8", cursor: "pointer", } : { cursor: "pointer", }} className="product-card" onClick={() => { setPriceId(product.prices[0]?.id) }} >
        <h2 style={{ color: "#7F7F7F", fontWeight: "bold" }}>{product.name}</h2>
        <p>{product.description}</p>
        {product.prices.length > 0 ? (
          <p style={{ fontWeight: "bold" }}>Price: {product.prices[0].unit_amount / 100} {currency.toUpperCase()}</p>
        ) : (
          <p>No price available in {currency.toUpperCase()}</p>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
