import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import ReactLoading from 'react-loading';
const stripePromise = loadStripe('pk_test_51PbHro2Kpf1VYpDCO5tRBKZdspyyMh8hefrSzlbauQFtH81QDn4fTBtQqdH6kMc4J1LcKWSGSYnocjmhw021zJ3e003BV0kl1x');

const CheckoutForm = ({ priceId }) => {
    const [currency, setCurrency] = useState('usd');
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        // Function to detect user's currency based on location
        const detectCurrency = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                setCurrency(data.currency || 'usd');
            } catch (error) {
                console.error('Error detecting currency:', error);
            }
        };
        detectCurrency();
    }, []);

    const handleClick = async (event) => {
        event.preventDefault();

        const response = await fetch(`https://mcp.cleprtech.com/stripe_api?path=create-checkout-session&priceId=${priceId}&currency=${currency}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ priceId, currency, }),
        });

        const session = await response.json();

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return (
        <button  style={{width:"420px",justifyContent:"center",display:"flex",backgroundColor:"#ffba00"}} disabled={!priceId} role="link" onClick={handleClick}>
        Start Free Trial
        </button>
    );
};
const ProductList = ({ products,loading,priceIdone }) => {
    const [price, setPrice] = useState(58);
    const [priceId, setPriceId] = useState(priceIdone);
    const [currency, setCurrency] = useState('USD');
    useEffect(() => {
        // Function to detect user's currency based on location
        const detectCurrency = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                setCurrency(data.currency || 'usd');
            } catch (error) {
                console.error('Error detecting currency:', error);
            }
        };
        detectCurrency();
    }, []);
    useEffect(() => {
        setPriceId(priceIdone)
    }, [priceIdone])
    
    return (
        <>

        <div  className="product-list">
           
        {/* <Spinner animation="grow" variant="primary" /> */}
        {loading?
        <ReactLoading type={"spinningBubbles"} color={"#00c3b8"} height={50} width={50} />:
        <>
        {console.log({priceId})}
            {products.map(product =>{ 
              return <>
                {product.active?<ProductCard currency={currency} priceId={priceId}key={product.id} product={product} setPriceId={setPriceId} />:null}
              </>
            })}
        </>
        }
        </div>
        <div style={{justifyContent:"center",display:"flex"}} className='Checkout'>
            <Elements stripe={stripePromise}>
                <CheckoutForm priceId={priceId} />
            </Elements>
        </div>
        </>
    );
};

export default ProductList;
