import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import CheckoutForm from './CheckoutForm';
import ProductList from './ProductList';
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
const stripePromise = loadStripe('pk_test_4TbuO6qAW2XPuce1Q6ywrGP200NrDZ2233'); // Replace with your Stripe publishable key

const App = () => {
  const [loading, setLoading] = useState(false);
  // const [currency, setCurrency] = useState('usd');

  // useEffect(() => {
  //   axios.get('http://localhost:3005/products')
  //     .then(response => {
  //       const country = response.data;
  //       console.log({country})
  //       if (country === 'US') {
  //         setPrice(1000); // $10.00
  //         setCurrency('usd');
  //       } else if (country === 'IN') {
  //         setPrice(750); // ₹750
  //         setCurrency('inr');
  //       } else {
  //         setPrice(800); // €8.00
  //         setCurrency('eur');
  //       }
  //     })
  //     .catch(error => console.error(error));
  // }, []);
  const [priceId, setPriceId] = useState('');
  const [products, setProducts] = useState([]);
  // useEffect(() => {
  //   const detectCurrency = async () => {
  //     try {
  //       const response = await fetch('https://ipapi.co/json/');
  //       const data = await response.json();

  //       axios.get(`http://localhost:3005/products?active=true&currency=${data.currency}`, {
  //         headers: {
  //           'Authorization': 'Bearer sk_test_51PbHro2Kpf1VYpDC5U3XYuljorVoXCSJJ0KlLA8QPtpWNQhlJBKDQzoMdDR5KV9Jvfmnwaiq9SKntLliqFPYjbIw00x14BJWu3'
  //         }
  //       })
  //         .then(response => {
  //           // console.log({ response })
  //           // console.log("first",response?.data[0]?.prices[0]?.id)
  //           setPriceId(response?.data[0]?.prices[0]?.id);
  //           setProducts(response.data);
  //         })
  //         .catch(error => {
  //           console.error('Error fetching product data:', error);
  //         });;
  //     } catch (error) {
  //       console.error('Error detecting currency:', error);
  //     }
  //   };
  //   detectCurrency();
  //   // Fetch product data from an API or local JSON file

  // }, []);

  useEffect(() => {
    setLoading(true)
    const detectCurrency = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();

        axios.get(`https://mcp.cleprtech.com/stripe_api?path=products&active=true&currency=${data.currency}`, {
          headers: {
            'Authorization': 'Bearer sk_test_51PbHro2Kpf1VYpDC5U3XYuljorVoXCSJJ0KlLA8QPtpWNQhlJBKDQzoMdDR5KV9Jvfmnwaiq9SKntLliqFPYjbIw00x14BJWu3'
          }
        })
          .then(response => {
            setLoading(false)
            // console.log({response})
      setPriceId(response?.data[0]?.prices[0]?.id);
            setProducts(response.data);
          })
          .catch(error => {
            setLoading(false)
            console.error('Error fetching product data:', error);
          });;
      } catch (error) {
        console.error('Error detecting currency:', error);
      }
    };
    detectCurrency();
    // Fetch product data from an API or local JSON file

  }, []);
  return (
    // <Elements stripe={stripePromise}>
    //   <CheckoutForm price={price} currency={currency} />
    // </Elements>
    <div class="container" style={{ maxWidth: "750" }}>
      <div class="row">

        <div style={{ borderBottomColor: "#f8bf00", borderBottom: "2px solid #f8bf00", paddingBlock: "15px", marginBottom: "20px" }} class="row justify-content-center align-center">
          <div style={{ width: "300px", borderBottomColor: "#f8bf00", borderBottom: "2px" }} >
            <img style={{ width: "270px" }} src="https://dev-app.readabilitytutor.com/web/shopping-cart/v4/style/images/logo_red.png" />
          </div>
        </div>


        <div class="col-7"  >
          <div style={{textAlign:"center"}} class="">
            <div >
              <h3>Start your free 30-day trial</h3>
            </div>
          </div>
          <div>
            <div class="">
              <div >
                <form >
                  <h4 style={{ textAlign: "left" }}>Sign Up</h4>
                  <div style={{ marginBottom: '5px' }}>
                    <input
                      type="email"
                      id="email"
                      placeholder='email-address'
                      style={{ width: '100%', padding: '8px', marginTop: '5px', borderRadius: "" }}
                    />
                  </div>
                  <div style={{ marginBottom: '5px' }}>
                    <input
                      type="password"
                      id="password"
                      placeholder='Password'
                      style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                    />
                  </div>
                  {/* <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}>
          Login
        </button> */}
                </form>
              </div>
            </div>
          </div>
          <div >
            <div >
              <form>
                <h6 style={{ textAlign: "left" }} >Select your plan</h6>
              </form>
            </div>
          </div>
          <ProductList priceIdone={priceId} loading={loading} products={products} />
          <div >
            <div >
              <form style={{ padding: "8px", backgroundColor: "#ecf2f5", marginTop: "16px" }}>
                <p style={{ textAlign: "left", fontSize: "12px" }}>After your free trial, your account will be charged automatically at $139.99on our website using either credit card or PayPal. Cancel anytime via the Subscription menu in the mobile app. By continuing you agree to Readability's Terms of Use, Privacy Policy, and Payment Policy. This free trial offer is for new customers only.
                </p>
              </form>
            </div>
          </div>
          <div >
            <div >
              <form style={{ padding: "8px", backgroundColor: "#ecf2f5", marginTop: "16px" }}>
                <h6 style={{ padding: "8px", }}>
                  Why do you need my credit card for a free trial?
                </h6>
                <p style={{ textAlign: "left", fontSize: "12px" }}>
                  We ask for your credit card to allow your membership to continue after your free trial, should you choose not to cancel. This also allows us to reduce fraud and prevent multiple free trials for one person. This helps us deliver better service for all the honest customers.
                  Remember that we won't bill you anything during your free trial and that you can cancel at any moment before your trial ends.
                </p>
              </form>
            </div>
          </div>
        </div>

        <div class="col-5" >
          <div >
            <div class="col col-xs-6 col-sm-8 col-sm-push-5"  style={{padding:"10px 15px",textAlign: "center", backgroundColor: "#ecf2f5", marginTop: "16px" }}>
              {/* <form style={{ padding: "8px", backgroundColor: "#ecf2f5", marginTop: "16px" }}> */}
                <h6 style={{ padding: "18px", textAlign: "center" }}>
                  100% no-risk trial
                </h6>
                <p style={{ textAlign: "left", fontSize: "12px", margin: "2",}}>
                  <span style={{ fontSize: "16px", color: "#f28887", paddingRight: "10px" }}>  <i class="fa fa-check-circle" aria-hidden="true"></i></span>   Pay NOTHING for the first 30 -days
                </p>
                <p style={{ textAlign: "left", fontSize: "12px", margin: "2",}}>
                  <span style={{ fontSize: "16px", color: "#f28887", paddingRight: "10px" }}>  <i class="fa fa-check-circle" aria-hidden="true"></i></span>  Cancel at ANY time during your trial.
                </p>
              {/* </form> */}
              <hr></hr>
                  <p style={{color:"#00c3b8"}}>Get access to all features. 
                  </p>
                  <p style={{ textAlign: "left", fontSize: "12px", margin: "2",}}>
                  <span style={{ fontSize: "16px", color: "#ffba00", paddingRight: "10px" }}>  <i class="fa fa-check-circle" aria-hidden="true"></i></span>  Our breakthrough technology LISTENS and HELPS while your child READS
                </p>
                  <p style={{ textAlign: "left", fontSize: "12px", margin: "2",}}>
                  <span style={{ fontSize: "16px", color: "#ffba00", paddingRight: "10px" }}>  <i class="fa fa-check-circle" aria-hidden="true"></i></span>  Comprehension & vocabulary is as important as reading, we deliver both
                </p>
                  <p style={{ textAlign: "left", fontSize: "12px", margin: "2",}}>
                  <span style={{ fontSize: "16px", color: "#ffba00", paddingRight: "10px" }}>  <i class="fa fa-check-circle" aria-hidden="true"></i></span> See your Child’s progress with easy to understand dashboard and reports.
                </p>
                  <p style={{ textAlign: "left", fontSize: "12px", margin: "2",}}>
                  <span style={{ fontSize: "16px", color: "#ffba00", paddingRight: "10px" }}>  <i class="fa fa-check-circle" aria-hidden="true"></i></span>  Add up to 3 Kids with Unlimited Use.
                </p>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
};

export default App;
